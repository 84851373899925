import { VuexModule, Module, getModule, Mutation } from 'vuex-module-decorators';
import { InsuredModel, Insured, Detail } from './insured-types';
import store from '@/store/index';

@Module({
  namespaced: true, name: 'insured', dynamic: true, store,
})
class InsuredStore extends VuexModule {
  data: InsuredModel = new InsuredModel()

  get insureds() {
    return this.data.insureds;
  }

  get detail() {
    return this.data.detail;
  }

  get contractedPlanId() {
    return this.data.detail?.contractedPlanId;
  }

  @Mutation
  setInsureds(payload: Insured[]) {
    this.data.insureds = payload;
  }

  @Mutation
  setContractedPlanId(payload: number) {
    if (!this.data.detail) return;

    this.data.detail.contractedPlanId = payload;
  }

  @Mutation
  resetModule() {
    this.data = new InsuredModel();
  }

  @Mutation
  resetDetail() {
    this.data.detail = new Detail();
  }
}

export default getModule(InsuredStore);
