import RuleApiFilter from '@/shared/models/rule-filter.model';
import SelectFieldInterface, { Datasource, Operator, Language, FieldFunction } from '@/shared/models/select-field-interface';
import SelectFieldType, { Rule } from '@/store/modules/rule/rule-types';
import qs from 'qs';
import { Api } from './api';

export default class RuleService {
  static async getRules(filter: RuleApiFilter = new RuleApiFilter()): Promise<Rule[]> {
    const { data: res } = await Api.get<Rule[]>('meta-rule', {
      params: { ...filter.toHttpParams() },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    });

    return res;
  }

  static async getRuleById(id: number): Promise<Rule> {
    const { data: res } = await Api.get<Rule>(`meta-rule/${id}`);
    return res;
  }

  static async getRuleByTags(tags: string[]): Promise<Rule[]> {
    const { data: res } = await Api.get<Rule[]>(`meta-rule?tags=${tags}`);
    return res;
  }

  static async getRuleTags(): Promise<string[]> {
    const { data: res } = await Api.get<string[]>('tag');
    return res;
  }

  static async getFieldTypes(): Promise<SelectFieldType[]> {
    const { data: res } = await Api.get<SelectFieldType[]>('field/type');
    return res;
  }

  static async getDefaultValueDescriptions(): Promise<SelectFieldInterface<string>[]> {
    const { data: res } = await Api.get<SelectFieldInterface<string>[]>('default-value/descriptions');
    return res;
  }

  static async getConstants(): Promise<SelectFieldInterface<string>[]> {
    const { data: res } = await Api.get<SelectFieldInterface<string>[]>('constant');
    return res;
  }

  static async getConditionTypes(): Promise<string[]> {
    const { data: res } = await Api.get<string[]>('condition/type/meta-rule');
    return res;
  }

  static async getOperators(): Promise<Operator[]> {
    const { data: res } = await Api.get<Operator[]>('operator?context=RULES');
    return res;
  }

  static async getDatasources(): Promise<Datasource[]> {
    const { data: res } = await Api.get<Datasource[]>('datasource');
    return res;
  }

  static async getLanguages(): Promise<Language[]> {
    const { data: res } = await Api.get<Language[]>('language');
    return res;
  }

  static async getFunctions(): Promise<FieldFunction[]> {
    const { data: res } = await Api.get<FieldFunction[]>('function');
    return res;
  }

  static async createRule(rule: Rule): Promise<void> {
    return Api.post('meta-rule', rule);
  }

  static async updateRule(rule: Rule): Promise<void> {
    return Api.put(`meta-rule/${rule.id}`, rule);
  }

  static async removeRule(ruleId: number): Promise<void> {
    return Api.delete(`meta-rule/${ruleId}`);
  }
}
