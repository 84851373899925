export class Insured {
  insuredId?: number;
  contractedPlanId?: number;
  insuredName?: string;
  insuredCpf?: string;
  insuredType?: string;
  holderName?: string;
  financialGroupId?: number;
  financialGroupName?: string;
  benefitCode?: string;
  benefitName?: string;
  insuranceCarrierId?: number;
  insuranceCarrierName?: string;
  contractId?: number;
  contractNumber?: string;
  subContractId?: number;
  subContractNumber?: string;
  planId?: number;
  planName?: string;
  startDate?: string;
  endDate?: string | null;
  cardNumber?: string;
  situation?: string;
  hasGracePeriod?: boolean | null;
}

export class Detail {
  contractedPlanId?: number;
}

export class InsuredModel {
  insureds?: Insured[];
  detail?: Detail;

  constructor() {
    this.insureds = [];
    this.detail = new Detail();
  }
}
