import {
  CollectorsConfigs,
  CollectorParams,
  CollectorModel,
  EditCollector,
  Collector,
} from './collectors-types';
import { VuexModule, Module, getModule, Mutation } from 'vuex-module-decorators';
import store from '@/store/index';

@Module({ namespaced: true, name: 'collectors', dynamic: true, store })
class CollectorStore extends VuexModule {
  data = new CollectorModel();

  @Mutation
  resetModule() {
    this.data = new CollectorModel();
  }

  @Mutation
  setCollector(payload: Collector) {
    this.data.collector = payload;
  }

  @Mutation
  setEditCollector(payload: EditCollector) {
    this.data.editCollector = payload;
  }

  @Mutation
  setCollectorParams(payload: CollectorParams) {
    this.data.collectorParams = payload;
  }

  @Mutation
  setCollectorsConfigs(payload: CollectorsConfigs) {
    this.data.collectorsConfigs = payload;
  }

  get collector() {
    return this.data.collector;
  }

  get editCollector() {
    return this.data.editCollector;
  }

  get collectorParams() {
    return this.data.collectorParams;
  }

  get collectorsConfigs() {
    return this.data.collectorsConfigs;
  }
}

export default getModule(CollectorStore);
