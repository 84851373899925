import {
  VuexModule, Module, getModule, MutationAction,
} from 'vuex-module-decorators';
import store from '@/store/index';
import FinancialGroupService from '@/services/financial-group-service';
import { FinancialGroupModel } from '@/store/modules/financial-group/financial-group-types';

@Module({
  namespaced: true, name: 'financial-group', dynamic: true, store,
})
class FinancialGroupStore extends VuexModule {
  financialGroups: FinancialGroupModel[] = [];

  @MutationAction
  async fetchFinancialGroups() {
    let financialGroups: FinancialGroupModel[] = await FinancialGroupService
      .getAllData();

    if (sessionStorage.getItem('financialGroupId') && financialGroups.length) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      financialGroups = financialGroups.filter((financialGroup) => financialGroup.id === sessionStorage.getItem('financialGroupId'));
    }

    return { financialGroups };
  }
}

export default getModule(FinancialGroupStore);
