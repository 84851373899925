import { VueClass } from 'vue-class-component/lib/declarations';
import ToastedPlugin from "vue-toasted";

export const configToasted = (vueInstance: VueClass<any>) => {
  vueInstance.use(ToastedPlugin, {
    position: 'bottom-center',
    duration: 5000,
    iconPack: 'fontawesome',
    singleton: true,
  });

  vueInstance.toasted.register('customError', (message) => message, {
    icon: 'exclamation',
    className: ['toast-error'],
  });

  vueInstance.toasted.register('customInfo', (message) => message, {
    icon: 'info',
    className: ['toast-info'],
  });

  vueInstance.toasted.register('customSuccess', (message) => message, {
    icon: 'check',
    className: ['toast-success'],
  });
};
