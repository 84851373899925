import {
  VuexModule, Module, getModule, Mutation,
} from 'vuex-module-decorators';
import store from '@/store/index';
import { DataLoad, DataLoadTypes } from './data-load-types';
import { boolean } from 'yup';

@Module({ namespaced: true, name: 'dataLoad', dynamic: true, store })
class DataLoadStore extends VuexModule {

  counter = 0;
  fileTypes: DataLoadTypes[] | [] = [];
  dataLoads: DataLoad[] | [] = [];
  files = [];
  dataLoad = {
    uploadType: '',
    createdBy: '',
    carrierId: '',
    financialGroupId: '',
    contractId: '',
    subContractId: '',
    status: '',
    competence: '',
  };
  errors = [];

  get fileCounter() {
    return this.counter;
  }

  get fileTypesData() {
    return this.fileTypes;
  }

  get fileDataLoads() {
    return this.dataLoads;
  }

  get dataFiles() {
    return this.files;
  }

  get dataLoadForm() {
    return this.dataLoad;
  }

  get dataErrors() {
    return this.errors;
  }

  @Mutation
  setCounter(newCounter: number) {
    this.counter = newCounter;
  }

  @Mutation
  setFileTypes(fileTypes: []) {
    this.fileTypes = fileTypes;
  }

  @Mutation
  setDataLoads(dataLoads: []) {
    this.dataLoads = dataLoads;
  }

  @Mutation
  setDataFiles(files: []) {
    this.files = files;
  }

  @Mutation
  setDataLoad(dataLoad: any) {
    this.dataLoad = dataLoad;
  }

  @Mutation
  setDataErrors(errors: any) {
    this.errors = errors;
  }

  @Mutation
  cleanDataLoad() {
    this.dataLoad = {
      uploadType: '',
      createdBy: '',
      carrierId: '',
      financialGroupId: '',
      contractId: '',
      subContractId: '',
      status: '',
      competence: '',
    };
  }
}

export default getModule(DataLoadStore);
