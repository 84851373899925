import axios from 'axios';
import { AuthenticationService } from '@/services/authentication-service';
// eslint-disable-next-line import/no-cycle
import MovementInsuranceCarrierReturnStore from '@/store/modules/movement-insurance-carrier-return/movement-insurance-carrier-return-module';
import { CriticismModel } from '@/store/modules/criticism/criticism-types';

const API = `${process.env.VUE_APP_API}/sdi`;
const ENDPOINT = '/movement-insurance-carrier-return';

export default class MovementInsuranceCarrierReturnService {
  static async getAllData(): Promise<CriticismModel[]> {
    const params = new URLSearchParams();
    params.append('sort', 'id,desc');
    params.append('size', '1000');
    const { data: res } = await axios.get(API + ENDPOINT, {
      params,
      headers: { Authorization: `${AuthenticationService.getAuthToken()}` },
    });
    return res;
  }

  static async getAllDataWithId(id: number): Promise<CriticismModel[]> {
    MovementInsuranceCarrierReturnStore.setLoading(true);
    const params = new URLSearchParams();
    params.append('movementRecordId', `${id}`);
    params.append('sort', 'id,desc');
    params.append('size', '1000');
    const { data: res } = await axios.get(API + ENDPOINT, {
      params,
      headers: { Authorization: `${AuthenticationService.getAuthToken()}` },
    });
    MovementInsuranceCarrierReturnStore.setLoading(false);
    return res;
  }

  static async registerCards(data: CriticismModel[]) {
    MovementInsuranceCarrierReturnStore.setLoading(true);
    await axios.post(API + ENDPOINT, data, { headers: { Authorization: `${AuthenticationService.getAuthToken()}` } });
    MovementInsuranceCarrierReturnStore.setLoading(false);
  }

  static async updateCard(data: CriticismModel[]) {
    MovementInsuranceCarrierReturnStore.setLoading(true);
    await axios.post(API + ENDPOINT, data, { headers: { Authorization: `${AuthenticationService.getAuthToken()}` } });
    MovementInsuranceCarrierReturnStore.setLoading(false);
  }
}
