import {
  VuexModule, Module, getModule, MutationAction, Mutation,
} from 'vuex-module-decorators';
import store from '@/store/index';
// eslint-disable-next-line import/no-cycle
import BatchService from '@/services/batch-service';
import { BatchModel } from '@/store/modules/batch/batch-types';

@Module({
  namespaced: true,
  name: 'batch',
  dynamic: true,
  store,
})
class BatchStore extends VuexModule {
  loading = false;

  currentPage = 0;

  registrationBatch: BatchModel | null = null;

  batchList: BatchModel[] = [];

  insuranceCarrierId = null;

  @Mutation
  setInsuranceCarrierId(insuranceCarrierId: any) {
    this.insuranceCarrierId = insuranceCarrierId;
  }

  setRegistration(registrationBatch: BatchModel) {
    this.registrationBatch = registrationBatch;
  }

  @Mutation
  setLoading(loadingState: boolean) {
    this.loading = loadingState;
  }

  @Mutation
  cleanStore() {
    this.loading = false;
    this.currentPage = 0;
    this.registrationBatch = null;
    this.batchList = [];
    this.insuranceCarrierId = null;
  }

  @MutationAction
  async fetchBatchList(page: number) {
    const batchList = await BatchService.getAllData(page);
    return { batchList };
  }

  @MutationAction
  async filterBatchlList(objectParamsFilter: Record<string, any>) {
    const batchList = await BatchService.filterData(objectParamsFilter);
    return { batchList };
  }

  get registrationBatchs() {
    return this.registrationBatch;
  }

  get isLoading() {
    return this.loading;
  }

  get batchInsuranceCarrierId() {
    return this.insuranceCarrierId;
  }
}

export default getModule(BatchStore);
