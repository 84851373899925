import {
  VuexModule, Module, getModule, MutationAction, Mutation,
} from 'vuex-module-decorators';
import store from '@/store/index';
import { CustomerReportAutomationModel } from '@/store/modules/customer-report-automation/customer-report-automation-types';

@Module({ namespaced: true, name: 'customer-report-automation', dynamic: true, store })
class CustomerreportAutomationStore extends VuexModule {

  form: CustomerReportAutomationModel = {};

  get customerForm() {
    return this.form;
  }

  @Mutation
  setFormSelected(form: CustomerReportAutomationModel) {
    this.form = form;
  }

  @Mutation
  cleanForm() {
    this.form = {};
  }
}

export default getModule(CustomerreportAutomationStore);
