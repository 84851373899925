export interface SftpConfig {
  supportPrivateKeyPath: string;
  supportKeyStoreFilePassword: string;
  supportSecurityMode: string;
  supportDataChannelProtectionLevel: string;
}

export interface ProxyConfig {
  proxyConfigurationService: string;
  host: string;
  port: string;
  type: string;
  username: string;
  password: string;
}

export class CollectorsConfigs {
  id?: number;
  url?: string;
  path?: string;
  port?: string;
  user?: string;
  password?: string;
  frequency?: string;
  fileFilterRegex?: string;
  pathFilterRegex?: string;
  deleteFileOriginal?: boolean;
  moveDestinationDirectory?: string;
  sizeBuffer?: string;
  conectionMode?: string;
  useUtf8Encoding?: boolean;
  tranferMode?: string;
  sftp?: SftpConfig;
  proxy?: ProxyConfig;
  status?: string;
  pathCollectedFile?: string;
  pathDocuments?: string;
  pathCollectedDocuments?: string;
  documentCutTime?: string;
}

export interface FinancialGroup {
  id: number;
  name: string;
}

export interface Contract {
  id: number;
  benefit: string;
  carrierName: string;
  policy: string;
  legalName: string;
  isSubContract: boolean;
  subContractNumber?: string | null;
}

export interface SubContract {
  id: number;
  benefit: string;
  carrierName: string;
  policy: string;
  legalName: string;
  isSubContract: boolean;
  subContractNumber: string;
}

export interface SftpConfig {
  supportPrivateKeyPath: string;
  supportKeyStoreFilePassword: string;
  supportSecurityMode: string;
  supportDataChannelProtectionLevel: string;
}

export interface ProxyConfig {
  proxyConfigurationService: string;
  host: string;
  port: string;
  type: string;
  username: string;
  password: string;
}

export interface FtpParameter {
  id: number;
  url: string;
  path: string;
  port: string;
  user: string;
  password: string;
  frequency: string;
  fileFilterRegex: string;
  pathFilterRegex: string;
  deleteFileOriginal: boolean;
  moveDestinationDirectory: string;
  sizeBuffer: string;
  conectionMode: string;
  useUtf8Encoding: boolean;
  tranferMode: string;
  sftp: SftpConfig;
  proxy: ProxyConfig;
  status: string;
  pathCollectedFile: string;
  pathDocuments: string;
  pathCollectedDocuments: string;
  documentCutTime: string;
}

export interface Config {
  id: number;
  type: string;
  name: string;
  cronExpression: string;
  ftpParameter: FtpParameter;
  emailParameter?: any;
  insuranceCarrierId?: any;
  financialGroupId: number;
  contractId: number;
  subContractId: number;
  parameters?: any;
}

export class EditCollector {
  id?: number | undefined;
  financialGroup?: FinancialGroup;
  entity?: string;
  contract?: Contract;
  subContract?: SubContract;
  insuranceCarrier?: any;
  configs?: Config[];
}

export interface FinancialGroup {
  id: number;
  name: string;
}

export interface ContractAndSubContract {
  benefit: string;
  carrierName?: string | null;
  id: number;
  isSubContract: boolean;
  legalName: string;
  policy: string;
  subContractNumber?: string | null;
}

export interface CollectorInterface {
  entity: string;
  types: string[];
  financialGroup: FinancialGroup;
  contract: ContractAndSubContract;
  subContract: ContractAndSubContract;
}

export class Collector {
  collectorType?: string;
  collectorName?: string;
  collector?: CollectorInterface;
}

export class CollectorParams {
  [key: string]: any;
}



export class CollectorModel {
  collectorsConfigs?: CollectorsConfigs;
  collectorParams?: CollectorParams;
  editCollector?: EditCollector;
  collector?: Collector;

  constructor() {
    this.collectorsConfigs = new CollectorsConfigs();
    this.collectorParams = new CollectorParams();
    this.editCollector = new EditCollector();
    this.collector = new Collector();
  }
}
