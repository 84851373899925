import PaginatedResponse from '@/shared/models/paginated-response';
import { MovementRuleModel } from '@/store/modules/movement-rule/movement-rule-types';
import { Rule } from '@/store/modules/rule/rule-types';
import { Api } from './api';

export default class MovementRuleService {
  static async getMovementRuleModels(query: {}): Promise<any> {
    const queryString = new URLSearchParams(query).toString();
    const { data: res } = await Api.get<PaginatedResponse<any>>(`movement-rules?${queryString}`);
    return res;
  }

  static async getMovementRuleModelsBySearch(query: {}): Promise<any> {
    const queryString = new URLSearchParams(query).toString();
    const { data: res } = await Api.get<PaginatedResponse<any>>(`movement-rules?${queryString}`);
    return res;
  }

  static async getRuleModels(): Promise<Rule[]> {
    const { data: res } = await Api.get<Rule[]>('rule/dynamic-rules');
    return res;
  }

  static async getRuleByTags(tags: string[]): Promise<Rule[]> {
    const { data: res } = await Api.get<Rule[]>(`rule/dynamic-rules?tags=${tags}`);
    return res;
  }

  static async getMovementRuleModelsById(movementRuleId: number): Promise<MovementRuleModel> {
    const { data: res } = await Api.get<MovementRuleModel>(`movement-rules/${movementRuleId}`);
    return res;
  }

  static async createMovementRule(movementRule: MovementRuleModel): Promise<void> {
    return Api.post('movement-rules', movementRule);
  }

  static async updateMovementRule(movementRule: MovementRuleModel): Promise<any> {
    const { data: res } = await Api.put(`movement-rules/${movementRule.id}`, movementRule);
    return res;
  }

  static async removeMovementRule(movementRuleId: number): Promise<void> {
    return Api.delete(`movement-rules/${movementRuleId}`);
  }
}
