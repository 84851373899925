import axios from 'axios';
import { CriticismModel } from '@/store/modules/criticism/criticism-types';
import { AuthenticationService } from '@/services/authentication-service';
// eslint-disable-next-line import/no-cycle
import CriticismStore from '@/store/modules/criticism/criticism-module';

const API = `${process.env.VUE_APP_API}/sdi`;
const ENDPOINT = '/movement-insurance-carrier-return';

export default class CriticismService {
  static async getAllDataByMovementId(movementId: number): Promise<CriticismModel[]> {
    let { data: res } = await axios.get(API + ENDPOINT, {
      params: {
        movementRecordId: movementId,
        returnType: 'FAIL',
      },
      headers: { Authorization: `${AuthenticationService.getAuthToken()}` },
    });

    if (res.content && res.content.length) {
      res = res.map((criticism: any) => ({
        id: criticism.id,
        rule: criticism.ruleId,
        message: criticism.message,
        criticismType: '',
        criticismField: criticism.field,
        criticismValue: criticism.fieldValue,
        movementInsuranceCarrierId: criticism.movementInsuranceCarrierId,
      }));
    }

    res.content.push({
      id: 0,
      rule: '1',
      message: '1',
      criticismType: '1',
      criticismField: '1',
      criticismValue: '1',
      movementInsuranceCarrierId: '1',
    });

    return res;
  }

  static async registerCriticism(data: any[]) {
    CriticismStore.setLoading(true);
    const { data: res } = await axios.post(API + ENDPOINT, data[0], { headers: { Authorization: `${AuthenticationService.getAuthToken()}` } });
    CriticismStore.setLoading(false);
    return res;
  }
}
