import {
  VuexModule, Module, getModule, MutationAction, Mutation,
} from 'vuex-module-decorators';
import store from '@/store/index';
// eslint-disable-next-line import/no-cycle
import ProtocolService from '@/services/protocol-service';
import { ProtocolModel } from '@/store/modules/protocol/protocol-types';

@Module({
  namespaced: true,
  name: 'protocol',
  dynamic: true,
  store,
})
class ProtocolStore extends VuexModule {
  loading = false;

  currentPage = 0;

  registrationProtocol: ProtocolModel | null = null;

  protocolList: ProtocolModel[] = [];

  @Mutation
  setRegistration(registrationProtocol: ProtocolModel) {
    this.registrationProtocol = registrationProtocol;
  }

  @Mutation
  setLoading(loadingState: boolean) {
    this.loading = loadingState;
  }

  @MutationAction
  async fetchProtocolList(page: number) {
    const protocolList = await ProtocolService.getAllData(page);
    return { protocolList };
  }

  @MutationAction
  async filterProtocolList(objectParamsFilter: Record<string, any>) {
    const protocolList = await ProtocolService.filterData(objectParamsFilter);
    return { protocolList };
  }

  get registrationProtocols() {
    return this.registrationProtocol;
  }

  get isLoading() {
    return this.loading;
  }
}

export default getModule(ProtocolStore);
