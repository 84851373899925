<template>
  <v-app style="background-color: var(--v-background-base)">
    <v-main>
      <router-view
        v-if="hasPermissionToLoad"
        v-show="!loaded"
      />

      <DialogReturnMessageErrors />

      <SnackbarCustomize ref="SnackbarCustomize" />

      <v-overlay :value="loadingRefreshToken">
        <v-progress-circular indeterminate size="120">
          <div>Carregando...</div>
        </v-progress-circular>
      </v-overlay>

      <FullScreenLoader
        v-if="loaded"
      />
    </v-main>
  </v-app>
</template>

<script>
import UserService from '@/services-http/security/UserService';
import UserUtils from '@/shared/utils/user-utils';
import DialogReturnMessageErrors from '@/components/DynamicDialogInformation/DialogReturnMessageErrors.vue';
import AuthService from '@/services-http/auth/AuthService';
import { loadingRefreshToken } from '@/shared/observable/loadingRefreshToken';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import FullScreenLoader from '@/components/FullScreenLoader/FullScreenLoader.vue'
import { mapGetters, mapMutations } from 'vuex';

export default {
  components: { DialogReturnMessageErrors, SnackbarCustomize, FullScreenLoader },
  name: 'App',
  data() {
    return {
      hasPermissionToLoad: false,
      hasInvalidToken: false,
      tokenSessionStorageKey: '@auth/token',
      odooTokenSessionStorageKey: '@auth-odoo/token',
      userSessionStorageKey: '@auth/user',
      tokenPrefix: 'Bearer ',
      loaded: false,
    };
  },

  computed: {
    ...mapGetters({
      appOrigin: 'user-info/appOrigin',
    }),
    loadingRefreshToken() {
      return loadingRefreshToken.isLoading;
    },
  },

  methods: {
    ...mapMutations({
      setAppOrigin: 'user-info/setAppOrigin',
    }),
    handlePageRefresh() {
      sessionStorage.setItem('@sdi/app_origin', this.appOrigin)
    },
    setSessionStoreData() {
      if (sessionStorage.getItem('@sdi/app_origin')) {
        this.setAppOrigin(sessionStorage.getItem('@sdi/app_origin'));
        sessionStorage.removeItem('@sdi/app_origin')
      }
    },
    async loadUserSessionData() {
      await this.userService.GetCurrent().then((response) => {
        const session = response.data;
        if (session) {
          const userSessionStorage = {
            authorities: session.authorities,
            user: {
              name: session.account && session.account.name ? session.account.name: null,
              email: session.account && session.account.email ? session.account.email : null,
            },
          };
          this.userUtils.createUserSessionStorage(userSessionStorage);
        }
      }).catch((error) => {
        if (error && error.response && error.response.status === 401) {
          this.showInvalidTokenMessage();
        }
      });
    },
    async loadTokenFull() {
      await this.authService.GetTokenFull().then((response) => {
        if (response && response.data) {
          const { token } = response.data;
          sessionStorage.setItem(this.tokenSessionStorageKey, `${this.tokenPrefix}${token}`);
          sessionStorage.setItem(this.odooTokenSessionStorageKey, `${this.tokenPrefix}${token}`);
        }
      });
      this.loaded = false;
    },
    validateAuthorities() {
      const { meta } = this.$route;
      const hasPermission = this.userUtils.hasAuthorityToAccess(meta);
      if (!hasPermission) {
        this.$router.push({ name: 'forbidden' });
      }
    },
    showInvalidTokenMessage() {
      this.$refs.SnackbarCustomize.open('error', 'Token inexistente');

      this.hasInvalidToken = true;
      if (this.$route.meta && (this.$route.meta.isRootPage || !this.$route.meta.requiresAuth)) {
        this.hasPermissionToLoad = true;
      }
    },
    verifyTokenChange() {
      if (this.$route.query && this.$route.query.token) {
        sessionStorage.removeItem(this.userSessionStorageKey);
      }
    },
  },

  beforeCreate() {
    this.loaded = true;
    this.userUtils = new UserUtils();
    this.authService = new AuthService();
  },

  mounted() {
    window.addEventListener('beforeunload', this.handlePageRefresh);
    this.setSessionStoreData();
    setTimeout(async () => {
      this.verifyTokenChange();
      if (!sessionStorage.getItem(this.tokenSessionStorageKey) && !sessionStorage.getItem(this.odooTokenSessionStorageKey)) {
        this.showInvalidTokenMessage();
      } else if (!this.userUtils.getUserSessionStorage()) {
        await this.loadTokenFull();
        this.userService = new UserService();
        await this.loadUserSessionData();
        this.validateAuthorities();
        this.hasPermissionToLoad = true;
      } else {
        this.validateAuthorities();
        this.hasPermissionToLoad = true;
        this.loaded = false;
      }
    }, 1000);
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handlePageRefresh);
  }
};
</script>
<style lang="scss">
@import '@/assets/css/main.scss';
@import '@/shared/styles/toasted.scss';

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .alterationColorText .v-text-field__slot input {
    color: rgb(25, 0, 255) !important;
  }

  .alterationColorBorder .v-input__slot fieldset {
    color: rgb(25, 0, 255) !important;
    border: 2px solid;
    background: #E3F2FD;
  }

 </style>
