/* eslint-disable */
import HttpService from '../HttpService'

export default class UserService {
  constructor() {
    this._httpService = new HttpService('/auth/');
  }

  async GetCurrent() {
    return await this._httpService.get('current');
  }
}
