import {
  VuexModule, Module, getModule, MutationAction, Mutation,
} from 'vuex-module-decorators';
import store from '@/store/index';
// eslint-disable-next-line import/no-cycle
import MovementInsuranceCarrierReturnService from '@/services/movement-insurance-carrier-return-service';
import { CriticismModel } from '../criticism/criticism-types';

@Module({
  namespaced: true, name: 'movement-insurance-carrier-return', dynamic: true, store,
})
class MovementInsuranceCarrierReturnStore extends VuexModule {
  loading = false;

  manualCriticismList: CriticismModel[] = [];

  @Mutation
  setLoading(loadingState: boolean) {
    this.loading = loadingState;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  @MutationAction
  async registerCards(cards: CriticismModel[]) {
    await MovementInsuranceCarrierReturnService.registerCards(cards);
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  @MutationAction
  async updateCard(card: CriticismModel[]) {
    await MovementInsuranceCarrierReturnService.updateCard(card);
  }

  @MutationAction
  async fetchManualCriticismList() {
    const manualCriticismList = await MovementInsuranceCarrierReturnService.getAllData();

    return { manualCriticismList };
  }

  @MutationAction
  async fetchManualCriticismListWithId(id: number) {
    const manualCriticismList = await MovementInsuranceCarrierReturnService.getAllDataWithId(id);

    return { manualCriticismList };
  }

  get isLoading() {
    return this.loading;
  }

  get manualCriticismListFailed() {
    const list = Object.values(this.manualCriticismList);
    const filteredList = list.find((item, index, array) => index === 0);
    return filteredList;
  }
}

export default getModule(MovementInsuranceCarrierReturnStore);
