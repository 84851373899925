import axios from 'axios';
import { AuthenticationService } from '@/services/authentication-service';
import { RuleCustomModel } from '@/store/modules/rule-custom/rule-custom-types';

const API = `${process.env.VUE_APP_API}/sdi`;
const ENDPOINT = '/rule';

export default class ProtocolService {
  static async getAllData(): Promise<RuleCustomModel[]> {
    const { data: res } = await axios.get(API + ENDPOINT, { headers: { Authorization: `${AuthenticationService.getAuthToken()}` } });
    return res;
  }
}
