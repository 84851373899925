import router from '@/router';
import { AuthErrors } from '@/shared/models/auth-errors.model';
import { AuthLocalStorageKeys } from '@/shared/models/auth-localstorage-keys';
import { AxiosRequestConfig } from 'axios';

export class AuthenticationService {
  static setAuthenticationToken(token: any) {
    return sessionStorage.setItem(AuthLocalStorageKeys.TOKEN, `Bearer ${token}`);
  }

  static hasPermission() {
    return !!this.getAuthToken();
  }

  static getAuthToken() {
    return sessionStorage.getItem(AuthLocalStorageKeys.TOKEN);
  }

  static async requestTokenInterceptor(config: AxiosRequestConfig) {
    if (config.headers.Authorization) {
      return config;
    }

    const authToken = AuthenticationService.getAuthToken();

    config.headers.Authorization = authToken;

    return config;
  }

  static async errorResponseInterceptor(error: any) {
    const { status, config: originalRequest } = error.response;

    if (status === AuthErrors.UNAUTHORIZED || status === AuthErrors.FORBIDDEN) {
      return router.push('/');
    }

    return Promise.reject(error.response);
  }
}
