<template>
  <div>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      top
      elevation="24"
      :color="snackbar.type"
    >
      <div class="text-center">{{ snackbar.text }}</div>
      <template v-slot:action="{ attrs }">
        <v-icon
          text
          dark
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          close
        </v-icon>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default ({
  name: 'SnackbarCustomize',

  data: () => ({
    snackbar: {
      show: false,
      type: '',
      text: '',
      timeout: 10000,
    },
  }),

  methods: {
    open(type, text, timeout) {
      this.snackbar = {
        show: true,
        type,
        text,
        timeout,
      };
    },
  },
});
</script>
