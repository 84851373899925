import {
  VuexModule, Module, getModule, MutationAction, Mutation,
} from 'vuex-module-decorators';
import store from '@/store/index';
import MovementService from '@/services/movement-service';
import { DetailMovementModel } from '@/store/modules/movement-detail/movement-detail-types';

@Module({
  namespaced: true, name: 'movement-detail', dynamic: true, store,
})
class MovementDetailStore extends VuexModule {
  movementDetail: DetailMovementModel | null = null;

  movementDetailList: DetailMovementModel[] = [];

  @Mutation
  setRegistration(movementDetail: DetailMovementModel) {
    this.movementDetail = movementDetail;
  }

  @MutationAction
  async fetchRegistrationMovement() {
    const movementDetailList = await MovementService.getDetailData();

    return { movementDetailList };
  }

  get movementDetails() {
    return this.movementDetail;
  }
}

export default getModule(MovementDetailStore);
