import router from '@/router';
import RuleService from '@/services/rule-service';
import RuleApiFilter from '@/shared/models/rule-filter.model';
import SelectFieldInterface, { Datasource, FieldFunction, Operator } from '@/shared/models/select-field-interface';
import store from '@/store/index';
import Vue from 'vue';
import {
  Action, getModule, Module, Mutation, MutationAction, VuexModule,
} from 'vuex-module-decorators';
import SelectFieldType, { Rule } from './rule-types';

@Module({ namespaced: true, name: 'rule', dynamic: true, store })
class RuleStore extends VuexModule {
  ruleList: Rule[] | null = null;

  rule: Rule | null = null;

  ruleListByTags: Rule[] | null = null;

  tags: string[] = [];

  fieldTypes: SelectFieldType[] = [];

  descriptions: SelectFieldInterface<string>[] = [];

  conditionTypes: string[] = [];

  datasource: Datasource[] = [];

  fieldFunctions: FieldFunction[] = [];

  fieldConstants: SelectFieldInterface<string>[] = [];

  operators: Operator[] = [];

  @MutationAction
  async fetchRuleList(filter: RuleApiFilter = new RuleApiFilter()) {
    try {
      const ruleList = await RuleService.getRules(filter);
      return { ruleList };
    } catch (error) {
      throw error;
    }
  }

  get all() {
    return this.ruleList;
  }

  @MutationAction
  async fetchRuleListByTag(tags: string[]) {
    try {
      const ruleListByTags = await RuleService.getRuleByTags(tags);
      return { ruleListByTags };
    } catch (error) {
      Vue.toasted.global.customError('Ocorreu um erro ao processar sua requisição, tente novamente mais tarde.');
    }
  }

  get filteredList() {
    return this.ruleListByTags;
  }

  @MutationAction
  async fetchRuleIndex(id: number) {
    const rule = await RuleService.getRuleById(id);
    return { rule };
  }

  get ruleIndex() {
    return this.rule;
  }

  @Action({ rawError: true })
  async saveRule(rule: Rule) {
    try {
      await RuleService.createRule(rule);
      Vue.toasted.global.customSuccess('Regra salva com sucesso!');
      setTimeout(() => {
        router.push({ name: 'Rule' });
      }, 1500);
    } catch (error) {
      throw error;
    }
  }

  @Action
  async removeRule(ruleId: number) {
    try {
      await RuleService.removeRule(ruleId);
      Vue.toasted.global.customSuccess('Regra removida com sucesso!');
      this.fetchRuleList();
    } catch (error) {
      Vue.toasted.global.customError('Ocorreu um erro ao processar sua requisição, tente novamente mais tarde.');
    }
  }

  @MutationAction
  async findRuleById(id: number) {
    try {
      const rule = await RuleService.getRuleById(id);
      const orderRule = { rule };
      orderRule.rule.conditions.sort((a: any, b: any) => a.order - b.order);
      return { rule };
    } catch (error) {
      Vue.toasted.global.customError('Ocorreu um erro ao processar sua requisição, tente novamente mais tarde.');
    }
  }

  @MutationAction
  async getRuleTags() {
    try {
      const tags = await RuleService.getRuleTags();
      return { tags };
    } catch (error) {
      // TODO: adicionar toasted
    }
  }

  get ruleTags() {
    return this.tags;
  }

  @MutationAction
  async getFieldTypes() {
    try {
      const fieldTypes = await RuleService.getFieldTypes();
      return { fieldTypes };
    } catch (error) {
      // TODO: adicionar toasted
    }
  }

  get ruleFieldTypes() {
    return this.fieldTypes;
  }

  @MutationAction
  async getDefaultValueDescriptions() {
    try {
      const descriptions = await RuleService.getDefaultValueDescriptions();
      return { descriptions };
    } catch (error) {
      // TODO: adicionar toasted
    }
  }

  get defaultValueDescriptions() {
    return this.descriptions;
  }

  @MutationAction
  async getConditionTypes() {
    try {
      const conditionTypes = await RuleService.getConditionTypes();
      return { conditionTypes };
    } catch (error) {
      // TODO: adicionar toasted
    }
  }

  @MutationAction
  async getDatasources() {
    try {
      const datasource = await RuleService.getDatasources();
      return { datasource };
    } catch (error) {
      // TODO: adicionar toasted
    }
  }

  @MutationAction
  async getFieldFunctions() {
    try {
      const fieldFunctions = await RuleService.getFunctions();
      return { fieldFunctions };
    } catch (error) {
      // TODO: adicionar toasted
    }
  }

  @MutationAction
  async getFieldConstants() {
    try {
      const fieldConstants = await RuleService.getConstants();
      return { fieldConstants };
    } catch (error) {
      // TODO: adicionar toasted
    }
  }

  @MutationAction
  async getOperators() {
    try {
      const operators = await RuleService.getOperators();
      return { operators };
    } catch (error) {
      // TODO: adicionar toasted
    }
  }

  @Mutation
  resetModule() {
    this.ruleList = null;
    this.rule = null;
    this.ruleListByTags = null;
    this.tags = [];
    this.fieldTypes = [];
    this.descriptions = [];
    this.conditionTypes = [];
    this.datasource = [];
    this.fieldFunctions = [];
    this.fieldConstants = [];
    this.operators = [];
  }
}

export default getModule(RuleStore);
