export default {
  'pt-BR': {
    common: {
      confirm: 'Confirmar',
      save: 'Salvar',
      cancel: 'Cancelar',
      edit: 'Editar',
      copy: 'Copiar',
      delete: 'Excluir',
      loading: 'Carregando...',
      dialogHeader: 'Confirmar ação',
      dialogText: 'Texto de ajuda para explicar o que acontece por padrão em uma ação de Dialog.',
      enter: 'enter',
      noResultsTo: 'Nenhum resultado para',
      press: 'Pressione',
      and: 'E',
      or: 'Ou',
    },
    rule: {
      title: 'Regras Gerais',
      self: 'Regra',
      table: {
        ruleName: 'Nome da Regra',
        message: 'Mensagem',
        actions: 'Ações',
        emptyMessage: 'Você não tem nenhuma regra',
        category: 'Categoria',
        field: 'Campo criticado',
      },
      conditions: {
        type: 'Tipo',
        value: 'Valor',
        param: 'Parâmetro',
        operator: 'Operador',
        firstField: 'Campo 1',
        secondField: 'Campo 2',
        thirdField: 'Campo 3',
        addCondition: 'Adicionar condição',
        addSubCondition: 'Adicionar Sub-Condição',
      },
      filterByCategory: 'Filtrar por categoria',
      filterInput: 'Digite a categoria para incluir ou criar',
      deleteHeader: 'Excluir regra',
      deleteText: 'Tem certeza que deseja excluir a regra abaixo?',
      new: 'Nova regra',
      edit: 'Editar regra',
      categoryHelper: 'Digite a categoria para incluir ou criar',
      categoryHelperKey: 'Pressione ENTER para incluir uma nova',
      movementColumns: 'Campo criticado',
      movementType: 'Tipo de movimentação',
      movementTypeOptions: {
        implantation: 'Implantação',
        inclusion: 'Inclusão',
        exclusion: 'Exclusão',
        alteration: 'Alteração',
      },
      ruleType: 'Tipo de Regra',
      ruleTypeOptions: {
        dynamic: 'Dinâmica',
        script: 'Script',
        groovy: 'Groovy'
      },
      scriptLanguage: 'Linguagem do script',
      defaultValueType: 'Tipo de valor padrão',
      value: 'Valor',
      description: 'Descrição',
      toCreateThisCategory: 'para criar esta categoria',
      // ...
    },
  },
};
