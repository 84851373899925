<template>
  <v-container fluid>
    <v-row justify="center" class="mt-3">
      <v-col cols="12" md="3" lg="3" align="center">
        <v-icon large color="primary">fas fa-unlink</v-icon>
      </v-col>
    </v-row>

    <v-row justify="center" class="mt-3">
      <v-col cols="12" md="3" lg="3" align="center">
        <h1 class="text--primary">Sua sessão expirou</h1>
      </v-col>
    </v-row>

    <v-row justify="center" class="pa-0 ma-0">
      <v-col cols="12" md="3" lg="3" align="center">
       <span>Faça o login novamente</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default { name: 'InvalidToken' };
</script>
