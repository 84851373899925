import axios from 'axios';
import { ProtocolModel } from '@/store/modules/protocol/protocol-types';
import { AuthenticationService } from '@/services/authentication-service';
// eslint-disable-next-line import/no-cycle
import ProtocolStore from '@/store/modules/protocol/protocol-module';

const API = `${process.env.VUE_APP_API}/sdi`;
const ENDPOINT = '/file-data';

export default class ProtocolService {
  static async getAllData(page: number): Promise<ProtocolModel[]> {
    ProtocolStore.setLoading(true);
    const params = new URLSearchParams();
    if (sessionStorage.getItem('financialGroupId')) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      params.append('financialGroup', sessionStorage.getItem('financialGroupId'));
    }

    params.append('sort', 'id,desc');
    params.append('page', `${page}`);
    params.append('size', '10');
    const { data: res } = await axios.get(API + ENDPOINT, {
      params,
      headers: { Authorization: `${AuthenticationService.getAuthToken()}` },
    });
    ProtocolStore.setLoading(false);
    return res;
  }

  static async downloadFile(id: number, filename: string) {
    await axios.get(`${API}${ENDPOINT}/${id}/data`, {
      responseType: 'blob',
      headers: { Authorization: `${AuthenticationService.getAuthToken()}` },
    })
      .then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', `${filename}`);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
  }

  static async filterData(objectParamsFilter: Record<string, any>) {
    ProtocolStore.setLoading(true);
    const params = new URLSearchParams();
    params.append('sort', 'id,desc');
    params.append('page', objectParamsFilter.page);
    params.append('size', '10');
    const { data: res } = await axios.get(API + ENDPOINT + objectParamsFilter.queryString, {
      params,
      headers: { Authorization: `${AuthenticationService.getAuthToken()}` },
    });
    ProtocolStore.setLoading(false);
    return res;
  }
}
