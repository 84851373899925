// eslint-disable-next-line import/no-cycle
import { AuthenticationService } from '@/services/authentication-service';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import Forbidden from '@/views/unaccessible/Forbidden.vue';
import InvalidToken from '@/views/unaccessible/InvalidToken.vue';
import UserUtils from '@/shared/utils/user-utils';

Vue.use(VueRouter);

const userUtils = new UserUtils();

const routes: Array<RouteConfig> = [

  // temp debug
  {
    path: '/forbidden',
    name: 'forbidden',
    component: Forbidden,
    meta: { isRootPage: true },
  },
  {
    path: '/invalid-token',
    name: 'InvalidToken',
    component: InvalidToken,
    meta: { isRootPage: true },
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/Home.vue'),
    meta: { isRootPage: true },
  },
  {
    path: '/home-all-menus',
    name: 'HomeAllMenus',
    component: () => import('@/views/home/HomeAllMenus.vue'),
    meta: { isRootPage: true },
  },
  {
    path: '/home-insurance-carrier',
    name: 'HomeInsuranceCarrier',
    component: () => import('@/views/home/HomeInsuranceCarrier.vue'),
    meta: { isRootPage: true },
  },
  {
    path: '/home-operations',
    name: 'HomeOperationsAndOperationsExecutive',
    component: () => import('@/views/home/HomeOperationsAndOperationsExecutive.vue'),
    meta: { isRootPage: true },
  },
  {
    path: '/home-rh-protegido',
    name: 'HomeRHProtegido',
    component: () => import('@/views/home/HomeRHProtegido.vue'),
    meta: { isRootPage: true },
  },
  {
    path: '/home-financial-group',
    name: 'HomeFinancialGroup',
    component: () => import('@/views/home/HomeFinancialGroup.vue'),
    meta: { isRootPage: true },
  },

  //*
  // LAYOUT MODEL
  //*
  {
    path: '/layout-model',
    name: 'LayoutModel',
    component: () => import('@/views/layoutModel/layoutModels.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_modelo_layout' },
  },

  {
    path: '/layout-model/configurations',
    name: 'InitialConfigs',
    component: () => import('@/views/layoutModel/initialConfigurations.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_modelo_layout' },
  },

  {
    path: '/layout-model/column-configurations',
    name: 'ColumnConfigs',
    component: () => import('@/views/layoutModel/columnConfigurations.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_modelo_layout' },
  },
  {
    path: '/layout-model/column-configurations/edit',
    name: 'ColumnEditConfigs',
    component: () => import('@/views/layoutModel/columnConfigurations.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_modelo_layout' },
  },
  {
    path: '/layout-model/column-configurations/copy',
    name: 'ColumnCopyConfigs',
    component: () => import('@/views/layoutModel/columnConfigurations.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_modelo_layout' },
  },

  {
    path: '/layout-model/transformation-configurations',
    name: 'TransformationConfigs',
    component: () => import('@/views/layoutModel/transformationsConfigurations.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_modelo_layout' },
  },
  {
    path: '/layout-model/transformation-configurations/edit',
    name: 'TransformationEditConfigs',
    component: () => import('@/views/layoutModel/transformationsConfigurations.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_modelo_layout' },
  },
  {
    path: '/layout-model/transformation-configurations/copy',
    name: 'TransformationCopyConfigs',
    component: () => import('@/views/layoutModel/transformationsConfigurations.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_modelo_layout' },
  },
  //*
  // LAYOUT IMPLANTATION
  //*
  {
    path: '/layout-import',
    name: 'LayoutImports',
    component: () => import('@/views/layoutImport/layoutImports.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_layout_importacao' },
  },
  {
    path: '/layout-import/configurations',
    name: 'LayoutImportConfigs',
    component: () => import('@/views/layoutImport/initialConfigurations.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_layout_importacao' },
  },
  {
    path: '/layout-import/column-configurations',
    name: 'LayoutImportColumnConfigs',
    component: () => import('@/views/layoutImport/columnConfigurations.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_layout_importacao' },
  },
  {
    path: '/layout-import/column-configurations/edit',
    name: 'LayoutImportColumnEditConfigs',
    component: () => import('@/views/layoutImport/columnConfigurations.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_layout_importacao' },
  },
  {
    path: '/layout-import/column-configurations/copy',
    name: 'LayoutImportColumnCopyConfigs',
    component: () => import('@/views/layoutImport/columnConfigurations.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_layout_importacao' },
  },
  {
    path: '/layout-import/transformation-configurations',
    name: 'LayoutImportTransformationConfigs',
    component: () => import('@/views/layoutImport/transformationConfigurations.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_layout_importacao' },
  },
  {
    path: '/layout-import/transformation-configurations/edit',
    name: 'LayoutImportTransformationEditConfigs',
    component: () => import('@/views/layoutImport/transformationConfigurations.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_layout_importacao' },
  },
  {
    path: '/layout-import/transformation-configurations/copy',
    name: 'LayoutImportTransformationCopyConfigs',
    component: () => import('@/views/layoutImport/transformationConfigurations.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_layout_importacao' },
  },
  //*
  // REGRAS GERAIS
  //*
  {
    path: '/rule',
    name: 'Rule',
    component: () => import('@/views/rule/GeneralRules.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_regras_gerais' },
  },
  {
    path: '/new-rule',
    name: 'RuleNew',
    component: () => import('@/views/rule/rule-new/RuleNew.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_regras_gerais' },
  },
  {
    path: '/copy/:copyId',
    name: 'CopyRule',
    props: true,
    component: () => import('@/views/rule/rule-new/RuleNew.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_regras_gerais' },
  },
  {
    path: '/rule/:id',
    name: 'RuleEdit',
    component: () => import('@/views/rule/rule-new/RuleNew.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_regras_gerais' },
    props: (route) => (
      {
        isEdit: true,
        idRule: route.params.id,
      }
    ),
  },
  //*
  // MOVEMENT RULES
  //*
  {
    path: '/movement-rule',
    name: 'MovementRule',
    component: () => import('@/views/movement-rule/MovementRule.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_regras_movimentacao' },
  },
  {
    path: '/new-movement-rule',
    name: 'MovementRuleNew',
    props: true,
    component: () => import('@/views/movement-rule/movement-rule-new/MovementRuleNew.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_regras_movimentacao_configuracao_editar_copiar' },
  },
  {
    path: '/movement-rule/edit/:id',
    name: 'MovementRuleEdit',
    props: true,
    component: () => import('@/views/movement-rule/movement-rule-new/MovementRuleNew.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_regras_movimentacao_configuracao_editar_copiar' },
  },
  //*
  // COLLECTORS
  //*
  {
    path: '/collectors',
    name: 'Collectors',
    component: () => import('@/views/collectors/Collectors.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_coletores_configuracao_edicao_copiar' },
  },
  {
    path: '/collectors/new',
    name: 'CollectorsNew',
    component: () => import('@/views/collectors/collector-new/CollectorNew.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_coletores_configuracao_edicao_copiar' },
  },
  {
    path: '/collectors/new/integration',
    name: 'CollectorIntegration',
    component: () => import('@/views/collectors/collector-integration/CollectorIntegration.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_coletores' },
  },
  //*
  // IMPLANTATION
  //*
  {
    path: '/dependents',
    name: 'Dependents',
    component: () => import('@/views/implantation/dependents.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_inclusao_grupo_familiar' },
  },
  {
    path: '/address-bank-information',
    name: 'AddressBankInformation',
    component: () => import('@/views/implantation/addressBankInformation.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_inclusao_grupo_familiar' },
  },
  {
    path: '/beneficiary-additional-information',
    name: 'BeneficiaryAdditionalInformation',
    component: () => import('@/views/implantation/beneficiaryAdditionalInformation.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_inclusao_grupo_familiar' },
  },
  {
    path: '/beneficiary-plan-information',
    name: 'BeneficiaryPlanInformation',
    component: () => import('@/views/implantation/beneficiaryPlanInformation.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_inclusao_grupo_familiar' },
  },
  {
    path: '/family-group-inclusion-update',
    name: 'FamilyGroupInclusionUpdate',
    component: () => import('@/views/implantation/familyGroupInclusionUpdate.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_inclusao_grupo_familiar' },
  },
  //*
  // ALTERATION
  //*
  {
    path: '/search-holder-dependent',
    name: 'SearchHolderDependent',
    component: () => import('@/views/manualMovement/alteration/searchHolderDependent.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_alteracao_beneficiario' },
  },
  {
    path: '/alteration',
    name: 'Alteration',
    component: () => import('@/views/manualMovement/alteration/alteration.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_alteracao_beneficiario' },
  },
  {
    path: '/dependent-alteration',
    name: 'DependentAlteration',
    component: () => import('@/views/manualMovement/alteration/dependentAlteration.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_alteracao_beneficiario' },
  },
  //*
  // INCLUSION
  //*
  {
    path: '/dependent-inclusion-search-holders',
    name: 'DependentInclusion',
    component: () => import('@/views/manualMovement/dependentInclusion/dependentInclusionSearchHolders.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_inclusao_dependente' },
  },
  {
    path: '/dependent-inclusion-save',
    name: 'DependentInclusionSave',
    component: () => import('@/views/manualMovement/dependentInclusion/dependentInclusionSave.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_inclusao_dependente' },
  },
  {
    path: '/dependent-update',
    name: 'DependentUpdate',
    component: () => import('@/views/manualMovement/dependentInclusion/dependentUpdate.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_inclusao_dependente' },
  },
  //*
  // DELETE
  //*
  {
    path: '/search-holder-dependent-delete',
    name: 'ManualMovementDeleteSearch',
    component: () => import('@/views/manualMovement/exclusion/searchHolderDependentDelete.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_exclusao_beneficiario' },
  },
  {
    path: '/holder-delete',
    name: 'HolderDelete',
    component: () => import('@/views/manualMovement/exclusion/holderDelete.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_exclusao_beneficiario' },
  },
  {
    path: '/dependent-delete',
    name: 'DependentDelete',
    component: () => import('@/views/manualMovement/exclusion/dependentDelete.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_exclusao_beneficiario' },
  },
  {
    path: '/movement-record',
    name: 'Movement',
    component: () => import('@/views/dashboardMovementRecords/movementRecord.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_painel_movimentacoes_cadastrais' },
  },
  {
    path: '/movement-detail',
    name: 'MovementDetail',
    component: () => import('@/views/movement/detailMovements/detailsMovements.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_painel_movimentacoes_cadastrais' },
  },
  //*
  // REACTIVATION
  //*
  {
    path: '/reactivation',
    name: 'Reactivation',
    component: () => import('@/views/manualMovement/reactivation/searchHolderDependentReactivation.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_reativacao_beneficiario' },
  },
  //*
  // ELEGIBILITY
  //*
  {
    path: '/eligibility',
    name: 'Eligibility',
    component: () => import('@/views/elegibility/eligibility-config/Eligibility.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_elegibilidade' },
  },
  {
    path: '/eligibility-config',
    name: 'EligibilityCreatedAndUpdate',
    component: () => import('@/views/elegibility/eligibility-config/EligibilityCreatedAndUpdate.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_elegibilidade' },
  },
  //*
  // FINANCE
  //*
  {
    path: '/commission-extracts',
    name: 'ComissionExtracts',
    component: () => import('@/views/finance/commissionExtracts/CommissionExtracts.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_extratos_comissao' },
  },
  //*
  // BATCH
  //*
  {
    path: '/batch-configuration',
    name: 'BatchConfiguration',
    component: () => import('@/views/batch/batchConfiguration.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_configuracao_geracao_lote' },
  },
  {
    path: '/batch-configuration/new',
    name: 'BatchConfigNew',
    component: () => import('@/views/batch/batchConfigurationNew.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_configuracao_geracao_lote' },
  },
  {
    path: '/batch-configuration/edit/:id',
    name: 'BatchConfigEdit',
    component: () => import('@/views/batch/batchConfigurationNew.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_configuracao_geracao_lote' },
  },
  //*
  // SINISTER AND INVOICE
  //*
  {
    path: '/sinister-invoice',
    name: 'AccompanimentSinisterAndInvoice',
    component: () => import('@/views/sinisterAndInvoice/accompanimentSinisterAndInvoice.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_cargas_sinistros_faturas' },
  },
  //*
  // CONFIGURATION-IMPLANTATION
  //*
  {
    path: '/cache-synchronization',
    name: 'CacheSync',
    component: () => import('@/views/cacheManagement/CacheSyncs.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_sincronizacao_cache' },
  },
  {
    path: '/movement-synchronizations/financial-group',
    name: 'FinancialGroupSync',
    component: () => import('@/views/managementConfiguration/ManagementSyncs.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_configuracao_implantacao_grupo_financeiro' },
  },
  {
    path: '/movement-synchronizations/insurance-carrier',
    name: 'InsuranceCarrierSync',
    component: () => import('@/views/managementConfiguration/ManagementSyncs.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_configuracao_implantacao_operadora' },
  },
  {
    path: '/insuranceCarrierCriticismManagement',
    name: 'InsuranceCarrierCriticismManagement',
    component: () => import('@/views/insuranceCriticismManagement/insuranceCriticismManagement.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_manutencao_criticas_operadora_visualizar' },
  },
  //*
  // PARAMETERS-BROKER-VS-CARRIER
  //*
  {
    path: '/parametrization-broker-carrier',
    name: 'ParametrizationBrokerAndInsuranceCarrier',
    component: () => import('@/views/parametrizationMovementTypes/parametrizationBrokerAndInsuranceCarrier.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_parametrizacao_tipos_movimentacao' },
  },
  //*
  // PROCEDURE REGISTRATION
  //*
  {
    path: '/procedure-register-search',
    name: 'ProcedureRegisterSearch',
    component: () => import('@/views/procedureRegistration/procedureRegisterSearch.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_cadastro_procedimento' },
  },
  {
    path: '/procedure-register-form',
    name: 'ProcedureRegisterForm',
    component: () => import('@/views/procedureRegistration/procedureRegisterForm.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_cadastro_procedimento' },
  },
  //*
  // INSURED
  //*
  {
    path: '/search-insured',
    name: 'SearchInsured',
    component: () => import('@/views/insured/search/searchInsured.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_pesquisa_beneficiario' },
  },
  {
    path: '/detail/:insuredType',
    name: 'InsuredDetail',
    component: () => import('@/views/insured/detail/insuredDetails.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_detalhes_beneficiario' },
  },
  //*
  // TRACKING-HISTORY-LOG
  //*
  {
    path: '/history-log/:type/:id',
    name: 'HistoryLog',
    component: () => import('@/views/trackingHistoryLog/historyLog.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: 'sdi_visualizacao_historico_movimentacoes' },
  },
  //*
  // CUSTOMER-REPORT-AUTOMATION
  //*
  {
    path: '/customer-report-automation',
    name: 'CustomerReportAutomation',
    component: () => import('@/views/customerReportAutomation/CustomerReportAutomation.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: false },
  },
  {
    path: '/customer-report-automation-configuration',
    name: 'CustomerReportAutomationConfiguration',
    component: () => import('@/views/customerReportAutomation/CustomerReportAutomationForm.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: false },
  },
  //*
  // DATA-LOAD
  //*
  {
    path: '/data-load',
    name: 'DataLoad',
    component: () => import('@/views/dataLoad/DataLoad.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: false },
  },
  //*
  // OPERATIONAL-ROUTINES
  //*
  {
    path: '/operational-routines',
    name: 'OperationalRoutines',
    component: () => import('@/views/operationalRoutines/OperationalRoutines.vue'),
    meta: { isRootPage: true, requiresAuth: true, authority: false },
  },
];
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from) => {
  const { token } = to.query;
  const isRootPage = to.matched.some((record) => record.meta.isRootPage);
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (token && isRootPage) {
    AuthenticationService.setAuthenticationToken(token);
  }

  if (requiresAuth && !AuthenticationService.hasPermission()) {
    router.push({ path: '/' });
  }
});

router.beforeEach((to, from, next) => {
  const { token } = to.query;
  const isRootPage = to.matched.some((record) => record.meta.isRootPage);
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (token && isRootPage) {
    AuthenticationService.setAuthenticationToken(token);
  }

  if (requiresAuth) {
    if (!AuthenticationService.hasPermission()) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      next({ name: from.name });
    } else if (AuthenticationService.hasPermission() && !userUtils.hasAuthorityToAccess(to.meta)) {
      sessionStorage.setItem('@route', JSON.stringify(to));
      next({ name: 'forbidden' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
