import axios from 'axios';
import { AuthenticationService } from '@/services/authentication-service';
// eslint-disable-next-line import/no-cycle
import BatchStore from '@/store/modules/batch/batch-module';
import { BatchModel } from '@/store/modules/batch/batch-types';

const API = `${process.env.VUE_APP_API}/sdi`;
const ENDPOINT = '/movement-insurance-carrier';

export default class BatchService {
  static async getAllData(page: number): Promise<BatchModel[]> {
    BatchStore.setLoading(true);
    const params = new URLSearchParams();
    if (sessionStorage.getItem('financialGroupId')) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      params.append('financialGroup', sessionStorage.getItem('financialGroupId'));
    }
    params.append('sort', 'id,desc');
    params.append('page', `${page}`);
    params.append('size', '10');
    const { data: res } = await axios.get(API + ENDPOINT, {
      params,
      headers: { Authorization: `${AuthenticationService.getAuthToken()}` },
    });
    BatchStore.setLoading(false);
    return res;
  }

  static async insertProtocol(item: BatchModel) {
    await axios.put(API + ENDPOINT, {
      id: item.id,
      protocol: String(item.protocolNumber),
    },
    { headers: { Authorization: `${AuthenticationService.getAuthToken()}` } });
  }

  static async updateStatus(item: BatchModel) {
    await axios.put(API + ENDPOINT, {
      id: item.id,
      status: item.status,
    },
    { headers: { Authorization: `${AuthenticationService.getAuthToken()}` } });
  }

  static async filterData(objectParamsFilter: Record<string, any>) {
    BatchStore.setLoading(true);
    const params = new URLSearchParams();
    if (sessionStorage.getItem('financialGroupId')) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      params.append('financialGroup', sessionStorage.getItem('financialGroupId'));
    }
    params.append('sort', 'id,desc');
    params.append('page', objectParamsFilter.page);
    params.append('size', '10');
    const { data: res } = await axios.get(API + ENDPOINT + objectParamsFilter.queryString, {
      params,
      headers: { Authorization: `${AuthenticationService.getAuthToken()}` },
    });
    BatchStore.setLoading(false);
    return res;
  }
}
