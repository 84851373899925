import {
  VuexModule, Module, getModule, MutationAction, Mutation, Action,
} from 'vuex-module-decorators';
import store from '@/store/index';
import Vue from 'vue';
import router from '@/router';
import { Rule } from '@/store/modules/rule/rule-types';
import MovementRuleService from '@/services/movement-rule-service';
import { MovementRuleModel } from './movement-rule-types';

@Module({
  namespaced: true, name: 'movement-rule', dynamic: true, store,
})
class MovementRuleStore extends VuexModule {
  movementRuleList = null;

  movementRuleListBySearch = null;

  ruleList: Rule[] = []

  ruleListByTags: Rule[] = []

  movementRuleModel: MovementRuleModel = new MovementRuleModel()

  @MutationAction
  async fetchMovementRuleModelList(query: {}) {
    // eslint-disable-next-line max-len
    const movementRuleList = await MovementRuleService.getMovementRuleModels(query);
    return { movementRuleList };
  }

  @MutationAction
  async fetchMovementRuleModelListBySearch(query: {}) {
    const movementRuleListBySearch = await MovementRuleService.getMovementRuleModelsBySearch(query);
    return { movementRuleListBySearch };
  }

  @MutationAction
  async fetchRuleList() {
    const ruleList = await MovementRuleService.getRuleModels();
    return { ruleList };
  }

  @MutationAction
  async fetchRuleListByTag(tags: string[]) {
    try {
      const ruleListByTags = await MovementRuleService.getRuleByTags(tags);
      return { ruleListByTags };
    } catch (error) {
      Vue.toasted.global.customError('Ocorreu um erro ao processar sua requisição, tente novamente mais tarde.');
    }
  }

  @Action({ rawError: true })
  async fetchMovementRuleModelById(id: number) {
    try {
      const movementRule = await MovementRuleService.getMovementRuleModelsById(id);
      if (movementRule.subContract) {
        this.setMovementRule(movementRule);
      } else {
        movementRule.subContract = '';
        this.setMovementRule(movementRule);
      }
    } catch (error) {
      Vue.toasted.global.customError('Ocorreu um erro ao buscar uma Regra de Contrato, tente novamente mais tarde.');
    }
  }

  @Action
  async saveMovementRule(movementRule: MovementRuleModel) {
    try {
      await MovementRuleService.createMovementRule(movementRule);
      Vue.toasted.global.customSuccess('Regra salva com sucesso!');
      setTimeout(() => {
        router.push({ name: 'MovementRule' });
      }, 1500);
    } catch (error) {
      Vue.toasted.global.customError('Ocorreu um erro ao processar sua requisição, tente novamente mais tarde.');
    }
  }

  @Action({ rawError: true })
  async updateMovementRule() {
    try {
      delete (this.movementRuleModel as any).queryTransformation;
      // eslint-disable-next-line max-len
      await MovementRuleService.updateMovementRule({ ...this.movementRuleModel, financialGroup: {} });
      this.resetModule();

      Vue.toasted.global.customSuccess('Regra de Contrato atualizada com sucesso!');
      setTimeout(() => {
        router.push({ name: 'MovementRule' });
      }, 1000);
    } catch (error) {
      Vue.toasted.global.customError('Ocorreu um erro ao atualizar esta Regra de Contrato, tente novamente mais tarde.');
    }
  }

  @Action
  async removeMovementRule(movementRuleId: number) {
    try {
      await MovementRuleService.removeMovementRule(movementRuleId);
      Vue.toasted.global.customSuccess('Regra de contrato removida com sucesso!');
      const query: any = {};
      query.page = 0;
      query.size = 10;
      this.fetchMovementRuleModelList(query);
    } catch (error) {
      Vue.toasted.global.customError('Ocorreu um erro ao processar sua requisição, tente novamente mais tarde.');
    }
  }

  @Mutation
  setMovementRule(movementRule: MovementRuleModel) {
    this.movementRuleModel = movementRule;
  }

  @Mutation
  resetModule() {
    this.movementRuleModel = new MovementRuleModel();
  }
}

export default getModule(MovementRuleStore);
