import Vue from 'vue';
import Vuex, { createLogger } from 'vuex';
import modules from './modules';
import { AppStore } from './index-type';

Vue.use(Vuex);

export default new Vuex.Store<AppStore>({
  modules,
  plugins: process.env.NODE_ENV === 'development' ? [createLogger()] : [],
});
