import store from '@/store';
import { VuexModule } from 'vuex-module-decorators';

const requireModule = require.context('@/store/modules', true, /-module\.ts$/);
const modules: { [key: string]: any } = {};

async function loadModules() {
  await delay(100);
  requireModule.keys().forEach(filename => registerModule(filename));
}

function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function registerModule(filename: string) {
  const moduleConfig = requireModule(filename);

  if (moduleConfig && moduleConfig.default) {
    const moduleInstance = moduleConfig.default;

    if (isVuexModule(moduleInstance)) {
      const moduleName = getModuleName(filename);
      if (!isModuleRegistered(moduleName)) {
        store.registerModule(moduleName, moduleInstance);
        modules[moduleName] = moduleInstance;
      }
    }
  }
}

function isVuexModule(instance: any): instance is VuexModule {
  return instance.prototype instanceof VuexModule;
}

function getModuleName(filename: string): string {
  return filename
    .replace(/^.*[\\/]/, '')
    .replace(/-module\.ts$/, '')
    .replace(/^\w/, c => c.toLowerCase());
}

function isModuleRegistered(moduleName: string): boolean {
  return moduleName in store.state;
}

loadModules().catch(error => console.error('Failed to load modules:', error));

export default modules;
