import axios from 'axios';
import router from '@/router';
import { store, observableMutations } from '@/shared/observable/store';
import { observableMutationsRefreshToken } from '@/shared/observable/loadingRefreshToken';
import Rules from '@/shared/validators/formRules';
import UserUtils from '@/shared/utils/user-utils';
import userInfoModule from '@/store/modules/user-info/user-info-module';

const rule = new Rules();
const userUtils = new UserUtils();

const SESSION_STORAGE_TOKEN = '@auth/token';
const SESSION_ODOO_TOKEN = '@auth-odoo/token';
const TOKEN_PREFIX = 'Bearer ';
const APP_ORIGIN= '@sdi/app_origin';

const getActiveToken = () => (
  sessionStorage.getItem(SESSION_STORAGE_TOKEN) || sessionStorage.getItem(SESSION_ODOO_TOKEN) || ''
);

const getAppOrigin = () => {
  // if(sessionStorage.getItem(APP_ORIGIN))
  //   return sessionStorage.getItem(APP_ORIGIN)

  if (userInfoModule.data.appOrigin) {
    return userInfoModule.data.appOrigin;
  }

  userInfoModule.setAppOrigin('BACKOFFICE');
  return 'BACKOFFICE'
}

const setToken = (token) => {
  const fullToken = `${TOKEN_PREFIX}${token}`;
  sessionStorage.setItem(SESSION_STORAGE_TOKEN, fullToken);
  sessionStorage.setItem(SESSION_ODOO_TOKEN, fullToken);
};

export default class HttpService {
  constructor(serviceURL) {
    this.ws = this.loadAxios(serviceURL);
  }

  loadAxios(serviceURL) {
    const initAxios = axios.create({
      baseURL: `${process.env.VUE_APP_API}${serviceURL}`,
      headers: this.loadHeaders(),
    });

    if (serviceURL !== '/auth') {
      this.loadTokenFullIfNeeded();
    }

    this.interceptResponse(initAxios);

    return initAxios;
  }

  loadHeaders() {
    const tokenActive = getActiveToken();
    const appOrigin = getAppOrigin();

    return {
      Authorization: tokenActive,
      'Access-Control-Max-Age': 600,
      'Cache-Control': 'max-age=600',
      'X-Sdi-App-Origin': appOrigin
    };
  }

  get(path, params = null, responseType = null) {
    const config = responseType ? { params, ...responseType } : { params };
    return this.ws.get(path, config);
  }

  post(path, data) {
    return this.ws.post(path, data);
  }

  put(path, data) {
    return this.ws.put(path, data);
  }

  patch(path, data) {
    return this.ws.patch(path, data);
  }

  delete(path, params = null) {
    return this.ws.delete(path, { params });
  }

  async all(path, params) {
    const requests = params.map((param) => this.get(path, { [param.label]: param.value }));
    return axios.all(requests);
  }

  async loadTokenFullIfNeeded() {
    const tokenActive = getActiveToken();

    if (rule.checkExpiredToken(tokenActive)) {
      await this.refreshToken();
    }
  }

  async refreshToken() {
    observableMutationsRefreshToken.setLoadingRefreshToken(true);

    try {
      const response = await this.loadAxios('/auth').get('/token/refresh');
      if (response && response.data) {
        const { token } = response.data;
        setToken(token);
        await this.loadUserSessionData();
      }
    } catch (error) {
      console.error('Error refreshing tokens:', error);
    } finally {
      observableMutationsRefreshToken.setLoadingRefreshToken(false);
    }
  }

  async loadUserSessionData() {
    try {
      const response = await this.loadAxios('/auth').get('/current');
      const session = response.data;

      if (session) {
        const userSessionStorage = {
          authorities: session.authorities,
          user: {
            name: session.account && session.account.name ? session.account.name: null,
            email: session.account && session.account.email ? session.account.email : null,
          },
        };
        userUtils.createUserSessionStorage(userSessionStorage);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error('Error refreshing user:', error);
      }
    }
  }

  interceptResponse(initAxios) {
    initAxios.interceptors.response.use(undefined, async (error) => {
      if (error.response.status === 401 || error.response.status === 403) {
        sessionStorage.clear();
        router.push({ name: 'InvalidToken' });
      } else if (error.response.status === 400 || error.response.status === 409 || 
        error.response.status === 500 || error.response.status === 412 || error.response.status === 413) {
        await this.handleErrorResponse(error);
      }
      return Promise.reject(error);
    });
  }

  async handleErrorResponse(error) {
    if (error.response.data.error !== 'Internal Server Error' && !store.isCustomDialog) {
      observableMutations.setDialogInformation({
        viewMovements: false,
        skipDuplicityCheck: false,
        data: error.response.data,
        show: true,
      });
    }
  }
}
