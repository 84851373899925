import { ConditionTypeEnum } from "@/store/modules/rule/rule-types";

export class RulesFilters {

  static conditionTypeFilter(value: ConditionTypeEnum) {
    switch (value) {
      case ConditionTypeEnum.AND:
        return 'E'

      case ConditionTypeEnum.OR:
        return 'Ou'

      case ConditionTypeEnum.EMPTY:
        return 'Vazio'

      case ConditionTypeEnum.COMA:
        return 'Vírgula'

      default:
        return '';
    }
  }

}