import { QueryParams } from './query-params.type';

export default class Pagination {
  size: number;
  page: number;

  constructor() {
    this.page = 0;
    this.size = 10;
  }

  bindWithQuery(queryParams: QueryParams<Pagination>): void {
    this.page = queryParams.page || 0;
    this.size = queryParams.size || 0;
  }

  toHttpParams(): QueryParams<Pagination> {
    const params: QueryParams<Pagination> = {}

    if (this.size) {
      params.size = this.size
    }

    if (this.page) {
      params.page = this.page
    }

    return params;
  }
}
