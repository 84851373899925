import {
  VuexModule, Module, getModule, MutationAction, Mutation,
} from 'vuex-module-decorators';
import store from '@/store/index';
// eslint-disable-next-line import/no-cycle
import MovementService from '@/services/movement-service';
import { MovementModel } from '@/store/modules/movement/movement-types';

@Module({ namespaced: true, name: 'movement', dynamic: true, store })
class MovementStore extends VuexModule {
  loading = false;

  registrationMovement: MovementModel | null = null;

  movementList: MovementModel[] = [];

  @Mutation
  setRegistration(registrationMovement: MovementModel) {
    this.registrationMovement = registrationMovement;
  }

  @Mutation
  setLoading(loadingState: boolean) {
    this.loading = loadingState;
  }

  @MutationAction
  async fetchMovementList(page: string) {
    const movementList = await MovementService.getAllData(page);
    return { movementList };
  }

  @MutationAction
  async filterMovementList(objectParamsFilter: Record<string, any>) {
    const movementList = await MovementService.filterData(objectParamsFilter);
    return { movementList };
  }

  get registrationMovements() {
    return this.registrationMovement;
  }

  get isLoading() {
    return this.loading;
  }
}

export default getModule(MovementStore);
