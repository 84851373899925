// eslint-disable-next-line max-classes-per-file
export class MovementRuleModel {
    id?: number;

    insuranceCarrier?: any;

    financialGroup: any;

    contract?: any;

    subContract?: any;

    status: boolean;

    rules?: Rule[];

    tags?: string[];

    movementTypes?: any[];

    constructor() {
      this.insuranceCarrier = {};
      this.financialGroup = {};
      this.contract = {};
      this.subContract = {};
      this.status = true;
    }
}

export class Rule {
    defaultValue?: string;

    configValue?: string;

    ruleId?: number;

    id?: number;

    movementTypes: any;
}

export enum MovimentationTypeEnum {
    INACTIVE = 'INACTIVE',
    RESTRICTIVE = 'RESTRICTIVE',
  }
