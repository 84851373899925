import Pagination from '@/shared/models/pagination.model';
import { QueryParams } from '@/shared/models/query-params.type';
import qs from 'qs';

export default class RuleApiFilter extends Pagination {
  tags?: string[];

  constructor() {
    super();
  }

  bindWithQuery(queryParams: QueryParams<RuleApiFilter>): void {
    super.bindWithQuery(queryParams as Pagination);
    if (queryParams.tags) {
      const { tags } = qs.parse({ tags: queryParams.tags } as any, { parseArrays: true });
      this.tags = tags as string[];
    }
  }

  toHttpParams(): QueryParams<RuleApiFilter> {
    const params: QueryParams<RuleApiFilter> = {};

    if (Array.isArray(this.tags) && this.tags.length > 0) {
      params.tags = this.tags
    }

    return params;
  }
}
