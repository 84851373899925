import { VuexModule, Module, getModule, Mutation } from 'vuex-module-decorators';
import { UserInfoModal } from './user-info-types'
import store from '@/store/index';

@Module({
  namespaced: true,
  name: 'user-info',
  dynamic: true,
  store
})

class UserInfoStore extends VuexModule {
  data: UserInfoModal = new UserInfoModal()

  get appOrigin() {
    return this.data.appOrigin;
  }

  @Mutation
  setAppOrigin(payload: string) {
    this.data.appOrigin = payload;
  }
}

export default getModule(UserInfoStore);
