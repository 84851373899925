import { VuexModule, Module, getModule, Mutation } from 'vuex-module-decorators';
import { HandlerModel, SearchParams } from './handler-types'
import store from '@/store/index';

@Module({
  namespaced: true,
  name: 'handler',
  dynamic: true,
  store
})

class HandlerStore extends VuexModule {
  data: HandlerModel = new HandlerModel()

  get searchParams() {
    return this.data.searchParams;
  }

  @Mutation
  setSearchParams(payload: SearchParams) {
    this.data.searchParams = payload;
  }
}

export default getModule(HandlerStore);
