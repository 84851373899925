export class SearchParams {
  financialGroupIds?: number[]
}

export class HandlerModel {
  searchParams?: SearchParams;

  constructor() {
    this.searchParams = new SearchParams();
  }
}
