import axios from 'axios';
import { MovementModel } from '@/store/modules/movement/movement-types';
import { DetailMovementModel } from '@/store/modules/movement-detail/movement-detail-types';
import { AuthenticationService } from '@/services/authentication-service';
// eslint-disable-next-line import/no-cycle
import MovementStore from '@/store/modules/movement/movement-module';

const DETAIL_URL = 'https://mocki.io/v1/21395abb-3508-453a-bdd0-d97daa30a700';

const API = `${process.env.VUE_APP_API}/sdi`;
const ENDPOINT = '/movement-record';

export default class MovementService {
  static async getAllData(page: string): Promise<MovementModel[]> {
    MovementStore.setLoading(true);
    const params = new URLSearchParams();
    if (sessionStorage.getItem('financialGroupId')) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      params.append('financialGroup', sessionStorage.getItem('financialGroupId'));
    }
    params.append('sort', 'id,desc');
    params.append('page', page);
    params.append('size', '10');
    const { data: res } = await axios.get(API + ENDPOINT, {
      params,
      headers: { Authorization: `${AuthenticationService.getAuthToken()}` },
    });
    MovementStore.setLoading(false);
    return res;
  }

  static async getDetailData(): Promise<DetailMovementModel[]> {
    const { data: res } = await axios.get(DETAIL_URL);
    return res;
  }

  static async filterData(objectParamsFilter: Record<string, any>) {
    MovementStore.setLoading(true);
    const params = new URLSearchParams();
    params.append('sort', 'id,desc');
    params.append('page', objectParamsFilter.page);
    params.append('size', '10');
    const { data: res } = await axios.get(API + ENDPOINT + objectParamsFilter.queryString, {
      params,
      headers: { Authorization: `${AuthenticationService.getAuthToken()}` },
    });
    MovementStore.setLoading(false);
    return res;
  }
}
