// External library imports
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import SimpleVueValidation from 'simple-vue-validator';
import VueTheMask from 'vue-the-mask';
import { Ripple } from 'vuetify/lib/directives';
import { VueMaskDirective, VueMaskFilter } from 'v-mask';
import 'reflect-metadata';

// Local imports
import messages from '@/lang/lang';
import App from './App.vue';
import router from './router';
import store from './store/index';
import vuetify from './plugins/vuetify';
import { configToasted } from './plugins/toasted';
import { configFilters } from './plugins/custom-filters';

// Initial configurations
Vue.config.productionTip = false;
Vue.use(VueI18n);
configToasted(Vue);
configFilters(Vue);
Vue.use(SimpleVueValidation);
Vue.use(VueTheMask);
Vue.directive('mask', VueMaskDirective);
Vue.directive('ripple', Ripple);
Vue.filter('mask', VueMaskFilter);

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'pt-BR',
  messages,
});

const vue = new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (create) => create(App),
});

router.onReady(() => {
  vue.$mount("#app");
});
