import {
  VuexModule, Module, getModule, MutationAction, Mutation,
} from 'vuex-module-decorators';
import store from '@/store/index';
import { CriticismModel } from '@/store/modules/criticism/criticism-types';
// eslint-disable-next-line import/no-cycle
import CriticismService from '@/services/criticism-service';

@Module({
  namespaced: true, name: 'criticism', dynamic: true, store,
})
class CriticismStore extends VuexModule {
  criticismModel: CriticismModel | null = null;

  criticismModelList: CriticismModel[] = [];

  loading = false;

  @Mutation
  setLoading(loadingState: boolean) {
    this.loading = loadingState;
  }

  @Mutation
  setCriticism(criticismModel: CriticismModel) {
    this.criticismModel = criticismModel;
  }

  @MutationAction
  async fetchAllByMovement(movementId: number) {
    const criticismModelList: CriticismModel[] = await CriticismService
      .getAllDataByMovementId(movementId);

    return { criticismModelList };
  }

  // @ts-ignore
  @MutationAction
  async registerCriticism(cards: any[]) {
    const res = await CriticismService.registerCriticism(cards);
    return { res };
  }

  get isLoading() {
    return this.loading;
  }

  get criticism() {
    return this.criticismModel;
  }

  get allCriticism() {
    return this.criticismModelList;
  }
}

export default getModule(CriticismStore);
