import {
  VuexModule, Module, getModule, MutationAction, Mutation,
} from 'vuex-module-decorators';
import store from '@/store/index';
import RuleCustomService from '@/services/rule-custom-service';
import { RuleCustomModel } from '@/store/modules/rule-custom/rule-custom-types';

@Module({
  namespaced: true,
  name: 'rule-custom',
  dynamic: true,
  store,
})
class RuleCustomStore extends VuexModule {
  loading = false;

  rules: RuleCustomModel[] = [];

  @Mutation
  setLoading(loadingState: boolean) {
    this.loading = loadingState;
  }

  @MutationAction
  async fetchRuleCustomList() {
    const rules = await RuleCustomService.getAllData();
    return { rules };
  }

  get isLoading() {
    return this.loading;
  }
}

export default getModule(RuleCustomStore);
