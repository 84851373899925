import axios from 'axios';
import { FinancialGroupModel } from '@/store/modules/financial-group/financial-group-types';
import { AuthenticationService } from '@/services/authentication-service';

const API = process.env.VUE_APP_API;
const ENDPOINT = '/odoo/financial_group/permiteds';

export default class FinancialGroupService {
  static async getAllData(): Promise<FinancialGroupModel[]> {
    const { data: res } = await axios.get(API + ENDPOINT, { headers: { Authorization: `${AuthenticationService.getAuthToken()}` } });
    return res;
  }
}
